import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Layout, SEO } from '../components/common';
import { Container, PageWrapper, Details, Paragraph, UnorderedList } from '../styles';
import { Header } from '../components/theme';

const ResponsiveContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    margin-bottom: 1rem;
    flex-direction: row;
    gap: 2rem;
  }
`;

/**
 * The Hub section is requested by Professor Agrawala. He wants to
 * highlight the public-facing, non-technical parts of MIND Lab
 */
const TheHub = function () {
  const query = useStaticQuery(graphql`
    query HubImageQuery {
      allFile(filter: { name: { eq: "highways" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO />
      <Header />
      <PageWrapper as={Container}>
        <Details>
          <div className="hub-page">
            <h1>The Hub</h1>

            <div css={{ width: '100%', marginBottom: '1rem' }}>
              <GatsbyImage image={query.allFile.edges[0].node.childImageSharp.gatsbyImageData} alt="" />
            </div>

            <Paragraph>
              The Hub at the University of Maryland focuses on catalyzing and propelling transformative research and
              development across the landscape of Computer Science with the aim of improving the quality of life.
            </Paragraph>
            <Paragraph>
              The Computer Science research at the University of Maryland is well recognized nationally and
              internationally with more than 50 full-time faculty members, 50 full-time researchers, and 300 graduate
              students. This research is supported by external funding of approximately $20 million a year from agencies
              such as NSF, DARPA, AFOSR, NIH, DOE. The areas of expertise include:
            </Paragraph>

            <ResponsiveContainerStyle>
              <UnorderedList>
                <li>Artificial Intelligence</li>
                <li>Augmented and Virtual Reality</li>
                <li>Big Data</li>
                <li>Bioinformatics and Computational Biology</li>
                <li>Computer Networking</li>
                <li>Computer Operating Systems</li>
                <li>Computer Vision</li>
                <li>Cyber Security</li>
                <li>Data Science</li>
              </UnorderedList>
              <UnorderedList>
                <li>Databases</li>
                <li>Graphics</li>
                <li>High Performance Computing</li>
                <li>Human Computer Interaction</li>
                <li>Numerical Analysis</li>
                <li>Programming languages</li>
                <li>Scientific Computing</li>
                <li>Software Engineering</li>
                <li>Theoretical Computer Science</li>
              </UnorderedList>
            </ResponsiveContainerStyle>

            <Paragraph>
              The undergraduate Computer Science program is highly sought by students and, with 2800 majors at present,
              its graduates are highly valued by the national IT industry.
            </Paragraph>
            <Paragraph>
              The HUB engages in a variety of collaborative projects with the industry as well as researchers in other
              disciplines at the University. As a result, the industrial collaborators engage the recognized experts in
              the field, students participating in the projects get exposed to real-world problems and are ready to
              tackle them upon graduation, and the researchers at the University develop an understanding of the
              problems faced by the industry.
            </Paragraph>
          </div>
        </Details>
      </PageWrapper>
    </Layout>
  );
};

export default TheHub;
